import { css } from '@emotion/react';
import { Box, Card, Button, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  AsynchronouslyLoadedSection,
  FancyCurrencyDisplay,
  PaginatedTableWrapper,
  StatusChip,
  Table,
  TableError,
  TableLoader,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import { MoveFundsIcon } from 'src/components/Icon';
import {
  FundsAssignmentDetailsModal,
  useFundsAssignmentDetailsModal,
} from 'src/components/modals/FundsAssignmentDetailsModal';
import {
  FundTransferDetailsModal,
  useFundTransferDetailsModal,
} from 'src/components/modals/FundTransferDetailsModal';
import { PageTabs } from 'src/components/PageTabs';
import { FilterType, TableFilters } from 'src/components/TableFilters';
import {
  useAppRoutes,
  useGetAccountOverview,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege } from 'src/types';
import { AccountStatus } from 'src/types/account';
import {
  type FundsTransferCleaned,
  type FundsTransferRaw,
} from 'src/types/funds-transfers';
import { queryKeys, SERVICE_BENJI_CARD_URLS } from 'src/utils';

import { cleanFundTransfers, cleanFundAssignments } from './utils';

import type { Column } from '@table-library/react-table-library/types/compact';
import type { PaginatedTableFilter } from 'src/components/PaginatedTableWrapper';
import type { StatusType } from 'src/components/StatusChip';
import type {
  FundsAssignmentCleaned,
  FundsAssignmentRaw,
} from 'src/types/funds-assignments';

const tabsKey = 'funds-management';

export const FundsTransfersPage = () => {
  const cardRef = useRef<HTMLDivElement>(null);
  const { navigate } = useAppRoutes();

  const { data: userData, isLoading: isUserLoading } = useGetUserDetails();
  const { accountDetails } = userData ?? {};
  const hasGroupsLedgerEnabled = accountDetails?.features.groupsLedger;
  const { data: accountOverview, isLoading: isBalanceLoading } =
    useGetAccountOverview();

  const { openModal: openBalanceTransferModal } = useFundTransferDetailsModal();
  const { openModal: openFundsAssignmentModal } =
    useFundsAssignmentDetailsModal();
  const { isLoading: areUserPrivilegesLoading, privileges } =
    useGetUserPrivileges();

  const columnsFundsTransfers: ({
    width: string;
  } & Column<FundsTransferCleaned>)[] = [
    {
      label: 'Date',
      renderCell: () => {
        return <span />;
      },
      width: '130px',
    },
    {
      label: 'Type',
      renderCell: ({ typeLabel }) => {
        return typeLabel;
      },
      width: 'minmax(150px, 200px)',
    },
    {
      label: 'Status',
      renderCell: ({ status, entityType }) => {
        return (
          <Box
            css={css`
              display: flex;
            `}
          >
            <StatusChip status={status} type={entityType as StatusType} />
          </Box>
        );
      },
      width: '120px',
    },
    {
      label: 'Amount',
      renderCell: ({ amountCents }) => {
        return <FancyCurrencyDisplay amountCents={amountCents ?? 0} isPlain />;
      },
      width: '140px',
    },
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];
  const columnsFundsAssignments: ({
    width: string;
  } & Column<FundsAssignmentCleaned>)[] = [
    {
      label: 'Date',
      renderCell: () => {
        return <span />;
      },
      width: '130px',
    },
    {
      label: 'Group',
      renderCell: ({ group }) => {
        return group.name;
      },
      width: 'minmax(200px, 320px)',
    },
    {
      label: 'Amount',
      renderCell: ({ amountCents }) => {
        return <FancyCurrencyDisplay amountCents={amountCents ?? 0} isPlain />;
      },
      width: '140px',
    },
    {
      label: 'Assigned By',
      renderCell: ({
        createdByInternalUserId,
        createdByInternalUserName,
        createdByExternalId,
      }) => {
        return `${
          createdByInternalUserId
            ? createdByInternalUserName
            : `${createdByExternalId} (via API)`
        }`;
      },
      width: '140px',
    },
    {
      // Spacer column to fill the remaining space
      label: '',
      renderCell: () => null,
      width: '1fr',
    },
  ];

  const isPageLoading = areUserPrivilegesLoading;

  const queryParamName = `${tabsKey}-tab`;
  const [searchParams] = useSearchParams();
  const currentTab = Number(searchParams.get(queryParamName));
  const isOnFundsAssignmentTab = currentTab === 1;

  const [fundAssignmentFilters, setFundAssignmentFilters] =
    useState<PaginatedTableFilter>({});

  return (
    <>
      <Box
        css={css`
          max-height: calc(100vh - 100px);
          height: 100%;
          flex: 1 1 auto;
          display: flex;
          flex-direction: column;
        `}
      >
        <Card
          component="div"
          css={css`
            display: flex;
            width: 100%;
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            gap: 8px;
            flex: 1 1 auto;
          `}
          ref={cardRef}
        >
          <Box
            css={css`
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
              align-content: center;
              gap: 16px;
              padding: 16px 16px 0;
            `}
          >
            <Typography
              variant="h2"
              css={css`
                flex-grow: 999;
              `}
            >
              Funds Management
            </Typography>
            <Box
              css={css`
                display: flex;
                flex-wrap: wrap-reverse;
                gap: 16px;
                flex-grow: 1;
                justify-content: flex-end;
                align-items: center;
              `}
            >
              {/* <SearchBar
                      {...searchProps}
                      isLoading={!!isLoading}
                    /> */}
              {privileges.balance && (
                <Box
                  css={css`
                    display: flex;
                    gap: 16px;
                    background-color: ${COLOR_PALETTE.lightBackground};
                    border-radius: 8px;
                    padding: 8px;
                    border: 1px solid ${COLOR_PALETTE.border};
                  `}
                >
                  <AsynchronouslyLoadedSection
                    isLoading={isBalanceLoading || isUserLoading}
                  >
                    <DataItemDisplay
                      label="Available Account Balance"
                      size="small"
                      value={
                        <FancyCurrencyDisplay
                          size="md"
                          amountCents={
                            accountOverview?.accountBalance.availableCents
                          }
                          className="highlight-block"
                        />
                      }
                    />
                    {hasGroupsLedgerEnabled && (
                      <DataItemDisplay
                        label="Unassigned Account Balance"
                        size="small"
                        value={
                          <FancyCurrencyDisplay
                            size="md"
                            amountCents={
                              accountOverview?.accountBalance
                                .unassignedAvailableCents
                            }
                            className="highlight-block"
                          />
                        }
                      />
                    )}
                  </AsynchronouslyLoadedSection>
                </Box>
              )}
              {privileges?.fund_balance && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() =>
                    navigate(
                      '/secure/funds-management/new-transfer',
                      isOnFundsAssignmentTab
                        ? {
                            queryParams: { 'move-funds-tab': '1' },
                          }
                        : {},
                    )
                  }
                  disabled={
                    isPageLoading ||
                    userData?.accountDetails.status !== AccountStatus.ACTIVE
                  }
                  startIcon={<MoveFundsIcon />}
                >
                  Manage Funds
                </Button>
              )}
            </Box>
          </Box>
          <AsynchronouslyLoadedSection isLoading={isPageLoading}>
            <PageTabs
              tabsKey={tabsKey}
              tabs={[
                {
                  label: 'Fund Transfers',
                  width: 224,
                  content: (
                    <PaginatedTableWrapper<
                      FundsTransferRaw,
                      FundsTransferCleaned
                    >
                      endpointUrl={
                        SERVICE_BENJI_CARD_URLS.FUND_TRANSFERS_GET_ALL
                      }
                      queryKeyBase={queryKeys.balance._baseKey}
                      dataFormatter={cleanFundTransfers}
                      requiredPrivileges={[Privilege.balance]}
                    >
                      {({
                        queryResult: { isLoading, isError },
                        pagination,
                        paginationData,
                        resultCount,
                        pageCount,
                      }) => (
                        <Box
                          css={css`
                            display: flex;
                            width: 100%;
                            align-items: flex-start;
                            display: flex;
                            flex-direction: column;
                            gap: 8px;
                            flex: 1 1 auto;
                          `}
                        >
                          <Table<FundsTransferCleaned>
                            data={paginationData}
                            pagination={pagination}
                            columns={columnsFundsTransfers}
                            isLoading={isLoading}
                            onRowClick={({ id, entityType }) => {
                              openBalanceTransferModal({
                                id,
                                type: entityType,
                              });
                            }}
                            columnWidths={columnsFundsTransfers
                              .map(({ width }) => width)
                              .join(' ')}
                            combinedFirstColumn={{
                              primaryColumnKey: 'prettyCreatedAtDate',
                              columnComparatorKey: 'isCreatedAtDateSameAsPrev',
                            }}
                            css={css`
                              margin-top: 10px;
                              width: 100%;
                            `}
                          />
                          <TableLoader isLoading={isLoading} />
                          <TableError isLoading={isLoading} isError={isError} />
                          <TableNoResults
                            isLoading={isLoading}
                            resultCount={resultCount}
                          />
                          <TablePagination
                            resultCount={resultCount}
                            pageCount={pageCount}
                            pagination={pagination}
                          />
                        </Box>
                      )}
                    </PaginatedTableWrapper>
                  ),
                },
                ...(hasGroupsLedgerEnabled
                  ? [
                      {
                        label: 'Fund Assignments',
                        width: 200,
                        content: (
                          <PaginatedTableWrapper<
                            FundsAssignmentRaw,
                            FundsAssignmentCleaned
                          >
                            endpointUrl={
                              SERVICE_BENJI_CARD_URLS.FUND_ASSIGNMENTS_GET
                            }
                            queryKeyBase={queryKeys.fundsAssignments._baseKey}
                            dataFormatter={cleanFundAssignments}
                            requiredPrivileges={[
                              Privilege.balance,
                              Privilege.cards,
                            ]}
                            filters={fundAssignmentFilters}
                          >
                            {({
                              queryResult: { isLoading, isError },
                              pagination,
                              paginationData,
                              resultCount,
                              pageCount,
                            }) => (
                              <Box
                                css={css`
                                  display: flex;
                                  width: 100%;
                                  align-items: flex-start;
                                  display: flex;
                                  flex-direction: column;
                                  gap: 8px;
                                  flex: 1 1 auto;
                                `}
                              >
                                <Box
                                  css={css`
                                    width: 100%;
                                    display: flex;
                                    gap: 8px 16px;
                                    flex-wrap: nowrap;
                                    padding: 0 16px;
                                  `}
                                >
                                  <TableFilters
                                    setFilters={setFundAssignmentFilters}
                                    priorityFilterCount={1}
                                    filters={[
                                      {
                                        name: 'groupId',
                                        label: 'Viewing Single Group',
                                        defaultValue: '',
                                        type: FilterType.CHIP,
                                      },
                                    ]}
                                  />
                                </Box>
                                <Table<FundsAssignmentCleaned>
                                  data={paginationData}
                                  pagination={pagination}
                                  columns={columnsFundsAssignments}
                                  isLoading={isLoading}
                                  onRowClick={({ id }) => {
                                    openFundsAssignmentModal(id);
                                  }}
                                  columnWidths={columnsFundsAssignments
                                    .map(({ width }) => width)
                                    .join(' ')}
                                  combinedFirstColumn={{
                                    primaryColumnKey: 'prettyCreatedAtDate',
                                    columnComparatorKey:
                                      'isCreatedAtSameAsPrev',
                                  }}
                                  css={css`
                                    margin-top: 10px;
                                    width: 100%;
                                  `}
                                />
                                <TableLoader isLoading={isLoading} />
                                <TableError
                                  isLoading={isLoading}
                                  isError={isError}
                                />
                                <TableNoResults
                                  isLoading={isLoading}
                                  resultCount={resultCount}
                                />
                                <TablePagination
                                  resultCount={resultCount}
                                  pageCount={pageCount}
                                  pagination={pagination}
                                />
                              </Box>
                            )}
                          </PaginatedTableWrapper>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          </AsynchronouslyLoadedSection>
        </Card>
      </Box>
      <FundTransferDetailsModal anchorEl={cardRef.current} />
      <FundsAssignmentDetailsModal anchorEl={cardRef.current} />
    </>
  );
};
