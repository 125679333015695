import { blue, red } from '@mui/material/colors';
import { createTheme, css, responsiveFontSizes } from '@mui/material/styles';

import { InfoIcon } from './components/Icon';

import type {
  ThemeOptions,
  Theme as MaterialUITheme,
} from '@mui/material/styles';

// Theme generated with: https://bareynol.github.io/mui-theme-creator/

// See [here](https://duncanleung.com/accessing-material-ui-theme-object-emotion-styled-component-css-prop/#emotion-css-string-styles---access-theme-object)
// for how to access theme object with the emotion css prop.

// Re-declare the emotion theme to have the properties of the MaterialUiTheme
declare module '@emotion/react' {
  export interface Theme extends MaterialUITheme {}
}

export const COLOR_PALETTE = {
  primary: '#f5d484',
  white: '#ffffff',
  darkBackground: '#1A1A1A',
  textOnDark: '#E7E7E7',
  lightTextOnDark: '#888888',
  lightTextOnLight: '#888888',
  textOnLight: '#262626',
  navSubHeader: '#F6F6F6',
  tableRowOddHighlight: '#F6F6F6',
  grayInputBackground: '#F6F6F6',
  cardPreviewCopyButtonHover: '#e1dede',
  dataItemLinkHover: '#f7f7f7',
  tableRowHover: '#dfdfdf',
  navItemHover: '#424242',
  darkerNavItem: '#262626',
  cardBorder: '#E7E7E7',
  border: '#E7E7E7',
  borderMid: '#888888',
  borderDark: '#262626',
  dataItemDisplayLabel: '#888888',
  dataItemDisplayValue: '#262626',
  cardDisplayText: '#262626',
  error: '#FD5045',
  warning: '#F2BE57',
  warningIcon: '#F2BE57',
  success: '#50C034',
  alertErrorBackground: 'rgba(255, 0, 0, 0.05)',
  alertWarningBackground: '#FFF7E7',
  alertCopy: '#454545',
  alertFilledErrorBackground: '#FF3A29',
  alertFilledSuccessBackground: '#34B53A',
  primaryButtonBackground: '#f5d484',
  errorButtonBackground: '#FD5045',
  secondaryButtonBackground: '#E7E7E7',
  secondaryButtonBackgroundHover: '#d0d0d0',
  tabsBorder: '#E7E7E7',
  tabsIndicator: '#F2BE57',
  tabsHover: '#313131',
  secondaryButtonColor: '#888888',
  lightButtonColor: '#F6F6F6',
  loaderSpinner: '#888888',
  inputLabel: '#888888',
  inputText: '#262626',
  inputBorder: '#E7E7E7',
  filterChip: '#F6F6F6',
  modalBorder: '#262626',
  modalShadow: 'rgba(0, 0, 0, 0.03)',
  modalHeaderBackground: '#262626',
  modalHeaderText: '#F6F6F6',
  switchBackground: '#F2BE57',
  switchBackgroundDisabled: '#E7E7E7',
  switchBackgroundOff: '#888888',
  switchLightState: '#E7E7E7',
  blueLink: '#02A0FC',
  dangerAccordionBackground: 'rgba(255, 0, 0, 0.05)',
  dangerAccordionText: '#FF3A29',
  chartBackground: '#E7E7E7',
  chartFilled: '#262626',
  lightBackground: '#F6F6F6',
  backButtonBackground: '#F1F5FE',
  backButtonBackgroundHover: '#e1e4ee',
  lightInputIcon: '#E7E7E7',
};

export const focusedCardShadow =
  'box-shadow: 0px 4px 36.5px -9px rgba(0, 0, 0, 0.15)';

const BASE_FONT_SIZE = 16;

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: COLOR_PALETTE.primary,
    },
    secondary: {
      main: blue[700],
    },
    background: {
      default: COLOR_PALETTE.white,
      paper: COLOR_PALETTE.white,
    },
  },
  typography: {
    fontFamily: '"Lexend", sans-serif',
    fontSize: BASE_FONT_SIZE,
    htmlFontSize: BASE_FONT_SIZE,

    allVariants: {
      color: COLOR_PALETTE.textOnLight,
    },

    h1: {
      fontSize: `${40 / BASE_FONT_SIZE}rem`,
      fontWeight: 500,
    },
    h2: {
      fontSize: `${20 / BASE_FONT_SIZE}rem`,
      fontWeight: 700,
    },
    h3: {
      fontSize: `${18 / BASE_FONT_SIZE}rem`,
      fontWeight: 600,
    },
    h4: {
      fontSize: `${14 / BASE_FONT_SIZE}rem`,
      fontWeight: 400,
      lineHeight: 1.42857,
    },
    h5: {
      fontSize: `${10 / BASE_FONT_SIZE}rem`,
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: `${14 / BASE_FONT_SIZE}rem`,
      fontWeight: 400,
      lineHeight: 1,
    },
    body2: {
      fontSize: `${14 / BASE_FONT_SIZE}rem`,
      fontWeight: 300,
      lineHeight: 1,
    },
    button: {
      fontSize: `${14 / BASE_FONT_SIZE}rem`,
      fontWeight: 400,
      textTransform: 'none',
    },
  },
  components: {
    MuiCard: {
      styleOverrides: {
        root: {
          border: `1px solid ${COLOR_PALETTE.cardBorder}`,
          boxShadow: 'none',
          borderRadius: '14px',
          backgroundColor: COLOR_PALETTE.white,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '12px',
          ':last-child': {
            paddingBottom: '12px',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            color: 'rgb(0, 0, 0)',
          },
          fontSize: '1.2rem',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          color: COLOR_PALETTE.textOnLight,
          textDecoration: 'underline',
          fontWeight: 400,
          '&:hover': {
            textDecoration: 'underline',
            color: COLOR_PALETTE.textOnLight,
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          borderRadius: '100px',
          padding: '10px 20px',
          display: 'flex',
          gap: '10px',
          lineHeight: 'normal',
          ...(ownerState.size === 'large' && {
            height: '46px',
            padding: '12px 16px',
            '.MuiSvgIcon-root': {
              fontSize: '22px',
            },
            '.MuiButton-icon': {
              margin: 0,
            },
          }),
          ...(ownerState.color === 'success' && {
            '&.Mui-disabled': {
              background: 'rgb(3 186 96 / 22%)',
            },
          }),
          ...(ownerState.variant === 'text' && {
            color: 'rgba(0, 0, 0, 0.87)',
          }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              color: 'rgba(0, 0, 0, 0.87)',
              borderColor: '#F5D48C',
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'error' && {
              color: COLOR_PALETTE.error,
              borderColor: COLOR_PALETTE.error,
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              backgroundColor: COLOR_PALETTE.primaryButtonBackground,
              color: COLOR_PALETTE.textOnLight,
              border: 'none',
              fontWeight: 500,
              fontSize: '15px',
              boxShadow: '0px 6px 10px 4px rgba(242, 190, 87, 0.26)',
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              backgroundColor: COLOR_PALETTE.secondaryButtonBackground,
              color: COLOR_PALETTE.secondaryButtonColor,
              border: 'none',
              fontSize: '15px',
              ':hover': {
                backgroundColor: COLOR_PALETTE.secondaryButtonBackgroundHover,
              },
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'error' && {
              backgroundColor: COLOR_PALETTE.errorButtonBackground,
              color: COLOR_PALETTE.white,
              border: 'none',
              fontSize: '12px',
            }),
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: COLOR_PALETTE.inputLabel,
          '&&.Mui-focused': {
            color: COLOR_PALETTE.inputLabel,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&&&.Mui-focused fieldset': {
            borderColor: COLOR_PALETTE.primary,
            borderWidth: '1px',
          },
          '&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline': {
            borderColor: red[700],
          },
          fieldset: {
            borderColor: COLOR_PALETTE.inputBorder,
          },
          '&&:hover fieldset': {
            borderColor: 'rgba(0, 0, 0, 0.4)',
          },
          '.MuiSelect-select': {
            color: COLOR_PALETTE.inputText,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInput-underline:after': {
            borderBottomColor: 'rgba(0, 0, 0, 0.87)',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              '& legend': {
                fontSize: '0.9rem',
              },
            },
            '&:hover fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.67)',
            },
            '&.Mui-disabled fieldset': {
              borderColor: 'inherit',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(0, 0, 0, 0.37)',
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '.MuiInputBase-input': {
            padding: '10px 8px',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&.Mui-selected': {
            color: 'inherit',
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => {
          const { severity, variant } = ownerState;
          return {
            padding: '14px',
            borderRadius: '14px',
            '.MuiAlert-message': {
              width: '100%',
            },
            ...(severity === 'warning' && {
              backgroundColor: COLOR_PALETTE.alertWarningBackground,
            }),
            ...(severity === 'error' && {
              backgroundColor: COLOR_PALETTE.alertErrorBackground,
            }),
            ...(variant === 'filled' &&
              severity === 'error' && {
                '&, .MuiAlert-message': { color: COLOR_PALETTE.white },
                backgroundColor: COLOR_PALETTE.alertFilledErrorBackground,
              }),
            ...(variant === 'filled' &&
              severity === 'success' && {
                '&, .MuiAlert-message': { color: COLOR_PALETTE.white },
                backgroundColor: COLOR_PALETTE.alertFilledSuccessBackground,
              }),
          };
        },
        icon: {
          padding: 0,
        },
        message: {
          alignSelf: 'center',
          padding: '0',
          fontSize: '14px',
          fontWeight: 400,
          color: COLOR_PALETTE.alertCopy,
          overflow: 'visible',
        },
      },
      defaultProps: {
        iconMapping: {
          warning: (
            <InfoIcon
              css={css`
                stroke: ${COLOR_PALETTE.warningIcon};
                height: 20px;
                width: 20px;
              `}
            />
          ),
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          display: 'flex',
          padding: '6px 10px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px',
          borderRadius: '100px',
          fontSize: '10px',
          fontWeight: 400,
          lineHeight: 'normal',
        },
        label: {
          padding: 0,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: COLOR_PALETTE.loaderSpinner,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          margin: '4px 0 0',
        },
      },
    },
  },
};

export const theme = responsiveFontSizes(createTheme(themeOptions));
