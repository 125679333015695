import { get, lowerCase, startCase } from 'lodash';

import {
  FundTransferTypeEnum,
  type FundsTransferCleaned,
  type FundsTransferRaw,
} from 'src/types/funds-transfers';
import { formatDateToMST } from 'src/utils';

import type {
  FundsAssignmentCleaned,
  FundsAssignmentRaw,
} from 'src/types/funds-assignments';

export const TypeLabels: Partial<Record<FundTransferTypeEnum, string>> = {
  [FundTransferTypeEnum.PAYOUT]: 'Payout To Bank',
  [FundTransferTypeEnum.TOP_UP]: 'External Transfer',
  [FundTransferTypeEnum.OUTBOUND_PAYMENT]: 'Outbound Payment',
  [FundTransferTypeEnum.RECEIVED_CREDIT]: 'External Transfer',
  [FundTransferTypeEnum.RECEIVED_DEBIT]: 'Payout To Bank',
};

export const cleanFundTransfers = (rawFundTransfers: FundsTransferRaw[]) =>
  rawFundTransfers.reduce((acc, balanceTransfer) => {
    const prevBalanceTransfer = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(
      new Date(balanceTransfer.created_at),
    );
    const status = lowerCase(balanceTransfer.status);
    const amountCents =
      balanceTransfer.amount_cents *
      ([
        FundTransferTypeEnum.RECEIVED_DEBIT,
        FundTransferTypeEnum.PAYOUT,
      ].includes(balanceTransfer.entity_type)
        ? -1
        : 1);
    const typeLabel =
      balanceTransfer.entity_type === FundTransferTypeEnum.PAYOUT &&
      get(balanceTransfer, 'metadata.treasury_migration') === 'true'
        ? 'Treasury Migration'
        : balanceTransfer.entity_type ===
            FundTransferTypeEnum.RECEIVED_CREDIT &&
          balanceTransfer.linked_flow_source_flow_type === 'payout'
        ? 'Treasury Migration'
        : TypeLabels[balanceTransfer.entity_type] ??
          startCase(balanceTransfer.entity_type);
    return [
      ...acc,
      {
        id: balanceTransfer.id,
        stripeId: balanceTransfer.stripe_id,
        createdAt: balanceTransfer.created_at,
        prettyCreatedAtDate,
        isCreatedAtDateSameAsPrev:
          prevBalanceTransfer?.prettyCreatedAtDate === prettyCreatedAtDate,
        amountCents,
        status,
        typeLabel,
        entityType: balanceTransfer.entity_type,
      } as FundsTransferCleaned,
    ];
  }, [] as FundsTransferCleaned[]);

export const cleanFundAssignments = (
  rawFundAssignments: FundsAssignmentRaw[],
) =>
  rawFundAssignments.reduce((acc, fundsAssignment) => {
    const prevFundsAssignment = acc[acc.length - 1];
    const prettyCreatedAtDate = formatDateToMST(
      new Date(fundsAssignment.created_at),
    );
    return [
      ...acc,
      {
        id: fundsAssignment.id,
        amountCents: fundsAssignment.amount_cents,
        status: fundsAssignment.status,
        type: fundsAssignment.type,
        createdAt: fundsAssignment.created_at,
        prettyCreatedAtDate,
        isCreatedAtSameAsPrev:
          prevFundsAssignment?.prettyCreatedAtDate === prettyCreatedAtDate,
        createdByExternalId: fundsAssignment.created_by_external_id,
        createdByInternalUserId: fundsAssignment.created_by_internal_user_id,
        createdByInternalUserName:
          fundsAssignment.user &&
          [
            fundsAssignment.user.first_name,
            fundsAssignment.user.last_name,
          ].join(' '),
        group: fundsAssignment.group,
        accountId: fundsAssignment.account_id,
      } as FundsAssignmentCleaned,
    ];
  }, [] as FundsAssignmentCleaned[]);
