import { useUser } from '@clerk/clerk-react';
import { useQuery } from '@tanstack/react-query';
import { lowerCase, padStart } from 'lodash';
import { generatePath } from 'react-router-dom';

import { SERVICE_BENJI_CARD_URLS, queryKeys } from 'src/utils';

import { useFetch } from './useFetch';
import { useGetUserPrivileges } from './useGetUserPrivileges';

import type { CardDetailsCleaned, CardDetailsRaw } from 'src/types';

export const useGetCard = ({ id }: { id: string }) => {
  const { fetchApi } = useFetch();
  const { isSignedIn } = useUser();
  const { privileges } = useGetUserPrivileges();

  const getCard = async ({ id }: { id: string }) => {
    const { data } = await fetchApi.get<CardDetailsRaw>(
      `${generatePath(SERVICE_BENJI_CARD_URLS.CARD_GET_DETAILS, {
        cardStripeId: id,
      })}`,
    );
    const status = lowerCase(data.card.status);
    return {
      id: data.card.id,
      type: data.card.type,
      stripeId: data.card.stripe_card_id,
      createdAt: data.card.created,
      createdByName: data.card.createdBy,
      createdByInternalId: data.card.created_by_internal,
      createdByExternalId: data.card.created_by_external,
      lastFour: data.card.last4,
      expiration: {
        month: data.card.exp_month,
        year: data.card.exp_year,
        prettyDateString: `${padStart(
          data.card.exp_month.toString(),
          2,
          '0',
        )}/${data.card.exp_year.toString().slice(-2)}`,
      },
      billingAddress: data.card.cardholder.billing.address,
      status,
      isActive: status === 'active',
      nickname: data.card.nickname,
      spendLimitCents: data.card.spend_limit_cents,
      spendLimitRemainingCents: data.card.spend_limit_remaining_cents,
      spentCents: data.card.spent_cents,
      spentPendingCents: data.card.spent_pending_cents,
      spentTotalCents:
        Math.abs(data.card.spent_cents ?? 0) +
        Math.abs(data.card.spent_pending_cents ?? 0),
      transactionsCount: data.transactionCount.count,
      cardholder: {
        id: data.card.cardholder.id,
        stripeId: data.card.cardholder.stripe_cardholder_id,
        name: {
          first: data.card.cardholder.individual?.first_name,
          last: data.card.cardholder.individual?.last_name,
          full: data.card.cardholder.name,
        },
        email: data.card.cardholder.email,
        phone: data.card.cardholder.phone_number,
        dob:
          data.card.cardholder.individual?.dob?.year &&
          data.card.cardholder.individual?.dob?.month &&
          data.card.cardholder.individual?.dob?.day
            ? `${data.card.cardholder.individual?.dob?.year ?? ''}-${`${
                data.card.cardholder.individual?.dob?.month ?? ''
              }`.padStart(2, '0')}-${`${
                data.card.cardholder.individual?.dob?.day ?? ''
              }`.padStart(2, '0')}`
            : '',
        type: data.card.cardholder.metadata.type,
        status: data.card.cardholder.status,
        group: data.card.cardholder.group,
      },
      reissuedFromCard: data.card.reissued_from_card
        ? {
            id: data.card.reissued_from_card.id,
            stripeCardId: data.card.reissued_from_card.stripe_card_id,
            last4: data.card.reissued_from_card.last4,
          }
        : undefined,
      reissuedToCard: data.card.reissued_to_card
        ? {
            id: data.card.reissued_to_card.id,
            stripeCardId: data.card.reissued_to_card.stripe_card_id,
            last4: data.card.reissued_to_card.last4,
          }
        : undefined,
    } as CardDetailsCleaned;
  };

  return useQuery({
    queryKey: queryKeys.cards.details({
      id,
    }),
    queryFn: () =>
      getCard({
        id,
      }),
    enabled: !!isSignedIn && !!privileges?.cards && !!id,
  });
};
