import { css } from '@emotion/react';
import { Close as CloseIcon } from '@mui/icons-material';
import {
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Alert,
  CircularProgress,
  IconButton,
  Popover,
  Link as StyledLink,
} from '@mui/material';
import { lowerCase, omit, startCase } from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Link,
  useSearchParams,
  generatePath as generateAPIPath,
} from 'react-router-dom';

import {
  CreditCardPreview,
  DataRow,
  FancyCurrencyDisplay,
  PaginatedTableWrapper,
  SlideLeftTransition,
  StatusChip,
  TableError,
  TableLoader,
  TableNoResults,
  TablePagination,
} from 'src/components';
import { CircleChart } from 'src/components/CircleChart';
import { DataItemDisplay } from 'src/components/DataItemDisplay';
import { useTransactionDetailsModal } from 'src/components/modals/TransactionDetailsModal';
import { ModalTabs } from 'src/components/ModalTabs';
import {
  TrackContext,
  TrackingContextProvider,
  TrackType,
  useModal,
  useTrack,
} from 'src/context';
import {
  useAppRoutes,
  useGetCard,
  useGetFeatures,
  useGetUserDetails,
  useGetUserPrivileges,
} from 'src/hooks';
import { cleanTransactionsAuthsCombined } from 'src/pages/Transactions/utils';
import { COLOR_PALETTE } from 'src/theme';
import { Privilege, type TransactionAuthCombinedCleaned } from 'src/types';
import { CardType, CardTypeLabel } from 'src/types/cards';
import { GroupStatus } from 'src/types/groups';
import {
  formatDateToMST,
  formatISODate,
  formatPhoneNumber,
  queryKeys,
  searchParamsToObject,
  SERVICE_BENJI_CARD_URLS,
} from 'src/utils';

import { CardIsActiveForm } from './CardIsActiveForm';
import { CardNicknameForm } from './CardNicknameForm';
import { CardSendForm } from './CardSendForm';
import { CardSpendControlForm } from './CardSpendControlForm';
import { SpendLimitHistory } from './SpendLimitHistory';
import { useSendCard } from './useSendCard';
import { cleanSendCardRecord } from './utils';

import type {
  SendCardRecordCleaned,
  SendCardRecordRaw,
  TransactionAuthCombinedRaw,
} from 'src/types';
import type { CardholderType } from 'src/types/cardholders';

export type UnsavedChanges = Record<
  string,
  { hasUnsavedChanges: boolean; label: string }
>;

const tabsKey = 'card';
const tabsQueryParamName = `${tabsKey}-tab`;

const TRANSACTIONS_PAGE_SIZE = 10;
const CARD_SEND_HISTORY_PAGE_SIZE = 5;

export const CARD_DETAILS_MODAL_ID = 'card-details-modal';

const dataRowLabelWidth = 120;

export const useCardDetailsModal = (
  props?: Partial<Pick<Parameters<typeof useModal>[0], 'onClose' | 'onOpen'>>,
) => {
  const { track } = useTrack();
  const { navigate, pathname, getRoutePathName } = useAppRoutes();
  const [searchParams] = useSearchParams();
  const currentSearchParams = searchParamsToObject(searchParams);

  const modal = useModal({
    modalId: CARD_DETAILS_MODAL_ID,
    onClose: () => {
      if (props?.onClose) {
        props.onClose();
      }
      navigate('/secure/cards', {
        queryParams: omit(currentSearchParams, [
          'selectedCardId',
          tabsQueryParamName,
        ]),
        preventScrollReset: true,
      });
    },
    onOpen: () => {
      track({
        label: 'Clicked open modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Card Details',
      });
      if (props?.onOpen) {
        props.onOpen();
      }
    },
  });
  return {
    ...modal,
    openModal: (id: string) => {
      const isSamePage = pathname === getRoutePathName('/secure/cards');
      const selectedTab = currentSearchParams[tabsQueryParamName];
      navigate('/secure/cards', {
        queryParams: {
          ...(isSamePage && currentSearchParams),
          ...(!!selectedTab && { [tabsQueryParamName]: selectedTab }),
          selectedCardId: id,
        },
        preventScrollReset: isSamePage,
      });
      modal.openModal();
    },
  };
};

const CardTrackingContextProvider = (props: { children: React.ReactNode }) => {
  const [searchParams] = useSearchParams();
  const selectedCardId = searchParams.get('selectedCardId');
  const { data: selectedCard } = useGetCard({ id: selectedCardId ?? '' });

  return (
    <TrackingContextProvider
      trackingProps={{
        cardId: selectedCard?.id,
        cardStripeId: selectedCard?.stripeId,
        cardExpMonth: selectedCard?.expiration?.month,
        cardExpYear: selectedCard?.expiration?.year,
        cardSpendControlAmountCents: selectedCard?.spendLimitCents,
        cardStatus: selectedCard?.status,
        cardholderStripeId: selectedCard?.cardholder.stripeId,
        cardCreatedAt: selectedCard?.createdAt
          ? new Date(selectedCard.createdAt).toISOString()
          : null,
      }}
      contextName={TrackContext.card}
      {...props}
    />
  );
};
const CardDetailsModalBase = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  const { navigate } = useAppRoutes();
  const { track, incrementMixpanelProfileData } = useTrack();
  const { privileges } = useGetUserPrivileges();
  const { features } = useGetFeatures();
  const { pathname, getRoutePathName, generatePath } = useAppRoutes();

  const { data: { accountDetails } = {}, isLoading: isLoadingUser } =
    useGetUserDetails();
  const orgCardTypes = accountDetails?.companyCardTypes;

  const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>({});
  const hasUnsavedChanges = Object.values(unsavedChanges).some(
    ({ hasUnsavedChanges }) => hasUnsavedChanges,
  );
  const handleSetUnsavedChanges = useMemo(
    () => (newUnsavedChanges: UnsavedChanges) => {
      setUnsavedChanges((unsavedChanges) => ({
        ...unsavedChanges,
        ...newUnsavedChanges,
      }));
    },
    [],
  );
  const [showUnsavedChangesWarning, setShowUnsavedChangesWarning] =
    useState(false);

  const { isOpen, openModal, closeModal } = useCardDetailsModal({
    onClose: () => {
      track({
        label: 'Clicked close modal',
        type: TrackType.action,
        actionType: 'click',
        modalName: 'Card Details',
        ...(hasUnsavedChanges && {
          unsavedChanges: Object.entries(unsavedChanges)
            // eslint-disable-next-line @typescript-eslint/no-unused-vars -- not using the key to filter
            .filter(([_key, { hasUnsavedChanges }]) => hasUnsavedChanges)
            .map(([label]) => label),
        }),
      });
    },
  });
  const { openModal: openTransactionModal } = useTransactionDetailsModal();
  const [searchParams] = useSearchParams();
  const selectedCardId = searchParams.get('selectedCardId');
  const { data: card, isLoading } = useGetCard({ id: selectedCardId ?? '' });

  useEffect(() => {
    if (!isOpen && selectedCardId) {
      openModal(selectedCardId);
    }
  }, [isOpen, openModal, selectedCardId]);

  const isSamePage = pathname === getRoutePathName('/secure/cards');
  useEffect(() => {
    if (isOpen && !selectedCardId && isSamePage) {
      closeModal();
    }
  }, [closeModal, isOpen, selectedCardId, isSamePage]);

  const [showSendCardForm, setShowSendCardForm] = useState(false);
  const { mutate: sendCard, isLoading: isResendingCard } = useSendCard();
  const { data: userData } = useGetUserDetails();

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      if (showUnsavedChangesWarning) {
        setUnsavedChanges({});
        setShowUnsavedChangesWarning(false);
        setShowSendCardForm(false);
        closeModal();
      } else {
        setShowUnsavedChangesWarning(true);
      }
    } else {
      setUnsavedChanges({});
      setShowUnsavedChangesWarning(false);
      setShowSendCardForm(false);
      closeModal();
    }
  };

  const {
    stripeId,
    cardholder,
    nickname,
    isActive,
    status,
    lastFour,
    expiration,
    billingAddress,
    spendLimitCents,
    spendLimitRemainingCents,
    spentTotalCents,
    transactionsCount,
    createdAt,
    createdByName,
    createdByExternalId,
    reissuedFromCard,
    reissuedToCard,
  } = card ?? {};
  const cardType = card?.type as CardType;
  const createdAtObj = !!createdAt && new Date(createdAt);

  const cardholderLink = generatePath('/secure/cardholders', undefined, {
    selectedCardholderId: cardholder?.stripeId ?? '',
  });
  const groupLink = generatePath('/secure/groups', undefined, {
    selectedGroupId: cardholder?.group?.id ?? '',
  });

  const isCardholderActive = lowerCase(cardholder?.status) === 'active';
  const isGroupInactive =
    !!cardholder?.group && cardholder.group.status === GroupStatus.INACTIVE;

  const hasSpendControlSet = !!spendLimitCents;

  const sendCardBlockers = [
    !hasSpendControlSet && 'spend limits must be set',
    !isActive && 'card must be active',
    !isCardholderActive && 'cardholder must be active',
    isGroupInactive && 'group must be active',
    !userData?.accountDetails.companySupportEmail &&
      `company support email must be set${
        privileges.company_details ? '' : ' by your admin'
      }`,
  ].filter(Boolean);
  const sendCardBlockersErrorMessage =
    sendCardBlockers.length > 1
      ? `${sendCardBlockers.slice(0, -1).join(', ')} and ${
          sendCardBlockers[sendCardBlockers.length - 1]
        }`
      : sendCardBlockers.join('');

  const tableContainerRef = useRef<HTMLDivElement>(null);

  return (
    <Popover
      open={isOpen}
      onClose={handleCloseModal}
      anchorEl={anchorEl}
      transformOrigin={{
        horizontal: 'right',
        vertical: 'center',
      }}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      css={(theme) => css`
        ${theme.breakpoints.down('sm')} {
          top: 14px;
        }

        z-index: 1300;

        .MuiPopover-paper {
          display: flex;
          flex-direction: column;
          max-height: 680px;
          height: 100%;
          padding: 0;
          margin: 0;
          max-width: 500px;
          width: 100%;
          border-radius: 14px;
          border: 1px solid ${COLOR_PALETTE.modalBorder};
          box-shadow: 0px 7px 21.8px 15px ${COLOR_PALETTE.modalShadow};
          @media (max-width: 524px) {
            max-width: calc(100vw - 24px);
          }
          @media (max-height: 710px) {
            max-height: calc(100vh - 70px);
            ${theme.breakpoints.down('sm')} {
              max-height: calc(100vh - 80px);
            }
          }
        }
      `}
      TransitionComponent={SlideLeftTransition}
    >
      <DialogTitle
        component="div"
        css={css`
          position: relative;
          display: flex;
          flex-wrap: nowrap;
          gap: 0 8px;
          align-items: center;
          padding: 18px 16px;
          min-height: 58px;
          padding-left: 44px;
          background-color: ${COLOR_PALETTE.modalHeaderBackground};
        `}
      >
        <IconButton
          aria-label="close"
          onClick={closeModal}
          css={css`
            color: ${COLOR_PALETTE.modalHeaderText};
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
          `}
        >
          <CloseIcon />
        </IconButton>
        <Typography
          variant="h3"
          css={css`
            flex: 1 1 200px;
            color: ${COLOR_PALETTE.modalHeaderText};
            font-weight: 700;
            line-height: normal;
          `}
        >
          Card Details
        </Typography>
        <Box
          css={css`
            display: flex;
            flex-wrap: wrap;
            flex: 1 1 auto;
            justify-content: flex-end;
          `}
        >
          {!isLoading && (
            <CardIsActiveForm
              isCardActive={
                !isGroupInactive &&
                isCardholderActive &&
                isActive &&
                !reissuedToCard
              }
              cardId={stripeId ?? ''}
              disabled={
                !stripeId ||
                isLoading ||
                status === 'canceled' ||
                !isCardholderActive ||
                isGroupInactive ||
                !privileges.card_details ||
                !!reissuedToCard
              }
            />
          )}
        </Box>
      </DialogTitle>
      <DialogContent
        css={css`
          display: flex;
          flex-direction: column;
          padding: 0;
        `}
      >
        {isLoading || isLoadingUser ? (
          <Box
            css={css`
              flex: 1 1 100%;
              display: flex;
              min-height: 200px;
              justify-content: center;
              align-items: center;
              padding: 24px;
            `}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <ModalTabs
              tabsKey={tabsKey}
              tabs={[
                {
                  label: 'Info',
                  content: (
                    <>
                      <Box
                        css={css`
                          display: flex;
                          justify-content: center;
                          padding: 8px 8px 0;
                        `}
                      >
                        <Box
                          css={css`
                            flex: 0 1 280px;
                          `}
                        >
                          <CreditCardPreview
                            cardholder={cardholder?.name.full ?? ''}
                            cardId={stripeId}
                            lastFour={lastFour}
                            expiry={expiration?.prettyDateString}
                          />
                        </Box>
                      </Box>
                      {reissuedToCard && (
                        <Box
                          css={css`
                            padding: 8px 18px 0;
                          `}
                        >
                          <Alert
                            severity="warning"
                            css={css`
                              flex: 1 1 100%;
                            `}
                          >
                            <Typography>
                              This card has been reissued as part of a migration
                              and is no longer active. Please use the new card.
                              If this card has already been sent via an email or
                              the embeded card view, the user will automatically
                              see the new card.{' '}
                              <Typography
                                component="a"
                                href={generatePath('/secure/cards', undefined, {
                                  selectedCardId: reissuedToCard.stripeCardId,
                                })}
                                css={css`
                                  color: inherit;
                                `}
                              >
                                View New Card
                              </Typography>
                            </Typography>
                          </Alert>
                        </Box>
                      )}
                      {isGroupInactive && (
                        <Box
                          css={css`
                            padding: 8px 18px 0;
                          `}
                        >
                          <Alert
                            severity="warning"
                            css={css`
                              flex: 1 1 100%;
                            `}
                          >
                            <Typography>
                              Group is not active. Card will not be able to be
                              used while group is inactive.{' '}
                              <Typography
                                component="a"
                                href={groupLink}
                                css={css`
                                  color: inherit;
                                `}
                              >
                                View Group
                              </Typography>
                            </Typography>
                          </Alert>
                        </Box>
                      )}
                      {!isCardholderActive && (
                        <Box
                          css={css`
                            padding: 8px 18px 0;
                          `}
                        >
                          <Alert
                            severity="warning"
                            css={css`
                              flex: 1 1 100%;
                            `}
                          >
                            <Typography>
                              Cardholder is not active. Card will not be able to
                              be used while cardholder is inactive.{' '}
                              <Typography
                                component="a"
                                href={cardholderLink}
                                css={css`
                                  color: inherit;
                                `}
                              >
                                View Cardholder
                              </Typography>
                            </Typography>
                          </Alert>
                        </Box>
                      )}
                      <Box
                        css={css`
                          padding: 10px 8px;
                        `}
                      >
                        <DataRow
                          label="Billing Address"
                          value={
                            <Box
                              css={css`
                                width: 100%;
                              `}
                            >
                              <Typography>{billingAddress?.line1}</Typography>
                              {!!billingAddress?.line2 && (
                                <Typography>{billingAddress?.line2}</Typography>
                              )}
                              <Typography>
                                {billingAddress?.city}, {billingAddress?.state}{' '}
                                {billingAddress?.postal_code}
                              </Typography>
                            </Box>
                          }
                          isPrivate
                          labelWidth={dataRowLabelWidth}
                        />
                        <DataRow
                          label="Nickname"
                          value={nickname ? nickname : ''}
                          isPrivate
                          labelWidth={dataRowLabelWidth}
                        />
                        <DataRow
                          label="Created On"
                          value={
                            !!createdAtObj && formatDateToMST(createdAtObj)
                          }
                          labelWidth={dataRowLabelWidth}
                        />

                        {reissuedFromCard ? (
                          <DataRow
                            label="Reissued From"
                            value={
                              <Typography
                                component="a"
                                href={generatePath('/secure/cards', undefined, {
                                  selectedCardId: reissuedFromCard.stripeCardId,
                                })}
                                css={css`
                                  color: inherit;
                                `}
                              >
                                **{reissuedFromCard.last4}
                              </Typography>
                            }
                            isPrivate
                            labelWidth={dataRowLabelWidth}
                          />
                        ) : (
                          <DataRow
                            label="Created By"
                            value={`${createdByName}${
                              createdByExternalId ? ' (via API)' : ''
                            }`}
                            isPrivate
                            labelWidth={dataRowLabelWidth}
                          />
                        )}
                        <DataRow
                          label="Status"
                          value={
                            <StatusChip status={status ?? ''} type="card" />
                          }
                          labelWidth={dataRowLabelWidth}
                        />
                        <DataRow
                          label="Cardholder"
                          value={
                            <Typography
                              component="a"
                              href={cardholderLink}
                              css={css`
                                color: inherit;
                              `}
                            >
                              {card?.cardholder.name.full ?? ''}
                            </Typography>
                          }
                          labelWidth={dataRowLabelWidth}
                        />
                        {features?.groups && (
                          <DataRow
                            label="Group"
                            value={
                              <Typography
                                component="a"
                                href={groupLink}
                                css={css`
                                  color: inherit;
                                `}
                              >
                                {card?.cardholder.group?.name ?? ''}
                              </Typography>
                            }
                            labelWidth={dataRowLabelWidth}
                          />
                        )}
                        {(orgCardTypes?.length ?? 0) > 1 && (
                          <DataRow
                            label="Card Type"
                            value={CardTypeLabel[cardType] ?? cardType}
                            labelWidth={dataRowLabelWidth}
                          />
                        )}
                        <DataRow
                          label="Email"
                          value={cardholder?.email ?? ''}
                          isPrivate
                          labelWidth={dataRowLabelWidth}
                        />
                        <DataRow
                          label="Phone No"
                          value={formatPhoneNumber(cardholder?.phone ?? '')}
                          isPrivate
                          labelWidth={dataRowLabelWidth}
                        />
                        <DataRow
                          label="DOB"
                          value={formatISODate(cardholder?.dob ?? '')}
                          isPrivate
                          labelWidth={dataRowLabelWidth}
                        />
                      </Box>
                    </>
                  ),
                },
                {
                  label: 'Spend Overview',
                  content: (
                    <>
                      {isActive && isCardholderActive ? (
                        <Box
                          css={css`
                            display: flex;
                            flex-wrap: wrap;
                            gap: 16px 8px;
                            flex: 1 1 100%;
                            padding: 16px 24px;
                          `}
                        >
                          <Box
                            css={css`
                              flex: 1 1 100%;
                              display: flex;
                              justify-content: center;
                            `}
                          >
                            <CircleChart
                              size={224}
                              strokeWidth={30}
                              value={
                                (spendLimitCents ?? 0) -
                                (spendLimitRemainingCents ?? 0)
                              }
                              max={spendLimitCents || 1}
                              label={
                                <DataItemDisplay
                                  css={css`
                                    gap: 0;
                                    .data-item-display-label {
                                      justify-content: center;
                                    }
                                  `}
                                  label="Spend Limit"
                                  value={
                                    <FancyCurrencyDisplay
                                      size="lg"
                                      amountCents={spendLimitCents ?? 0}
                                      css={css`
                                        font-size: 20px;
                                        font-weight: 700;
                                      `}
                                    />
                                  }
                                />
                              }
                            />
                          </Box>
                          <Box
                            css={css`
                              display: flex;
                              flex: 1 1 100px;
                              justify-content: center;
                            `}
                          >
                            <DataItemDisplay
                              css={css`
                                flex: 0 0 auto;
                                .data-item-display-label {
                                  justify-content: center;
                                }
                                .data-item-display-label {
                                  justify-content: center;
                                }
                                .data-item-display-value {
                                  text-align: center;
                                }
                              `}
                              label={
                                <>
                                  <Box
                                    css={css`
                                      height: 14px;
                                      width: 14px;
                                      border-radius: 50%;
                                      background-color: ${COLOR_PALETTE.chartFilled};
                                    `}
                                  />
                                  Used Spend Limit
                                </>
                              }
                              value={
                                <FancyCurrencyDisplay
                                  size="lg"
                                  amountCents={
                                    (spendLimitCents ?? 0) -
                                    (spendLimitRemainingCents ?? 0)
                                  }
                                />
                              }
                            />
                          </Box>

                          <Box
                            css={css`
                              display: flex;
                              flex: 1 1 100px;
                              justify-content: center;
                            `}
                          >
                            <DataItemDisplay
                              css={css`
                                flex: 0 0 auto;
                                .data-item-display-label {
                                  justify-content: center;
                                }
                                .data-item-display-value {
                                  text-align: center;
                                }
                              `}
                              label={
                                <>
                                  <Box
                                    css={css`
                                      height: 14px;
                                      width: 14px;
                                      border-radius: 50%;
                                      background-color: ${COLOR_PALETTE.chartBackground};
                                    `}
                                  />
                                  Remaining Spend
                                </>
                              }
                              value={
                                <FancyCurrencyDisplay
                                  size="lg"
                                  amountCents={spendLimitRemainingCents ?? 0}
                                />
                              }
                            />
                          </Box>
                        </Box>
                      ) : (
                        <Box
                          css={css`
                            padding: 16px 24px;
                          `}
                        >
                          <Alert severity="warning">
                            {[
                              ...(isCardholderActive ? [] : ['Cardholder']),
                              ...(isActive ? [] : ['Card']),
                            ].join(' and ')}{' '}
                            must be active in order to view spend limits.
                          </Alert>
                        </Box>
                      )}
                      <Box
                        css={css`
                          display: flex;
                          flex-wrap: wrap;
                          gap: 16px 8px;
                          flex: 1 1 100%;
                          padding: 16px 24px;
                        `}
                      >
                        <Box
                          css={css`
                            display: flex;
                            flex: 1 1 100px;
                            justify-content: center;
                            background-color: ${COLOR_PALETTE.lightBackground};
                            padding: 10px 10px 14px 10px;
                            border-radius: 8px;
                          `}
                        >
                          <DataItemDisplay
                            css={css`
                              flex: 0 0 auto;
                              gap: 10px;
                              .data-item-display-label {
                                justify-content: center;
                              }
                              .data-item-display-value {
                                text-align: center;
                              }
                            `}
                            label="Total Spent"
                            value={
                              <Typography
                                css={css`
                                  font-size: 20px;
                                  font-weight: 700;
                                `}
                              >
                                <FancyCurrencyDisplay
                                  amountCents={
                                    spentTotalCents ? spentTotalCents * -1 : 0
                                  }
                                  isPlain
                                />
                              </Typography>
                            }
                          />
                        </Box>
                        <Box
                          css={css`
                            display: flex;
                            flex: 1 1 100px;
                            justify-content: center;
                            background-color: ${COLOR_PALETTE.lightBackground};
                            padding: 10px 10px 14px 10px;
                            border-radius: 8px;
                          `}
                        >
                          <DataItemDisplay
                            css={css`
                              flex: 0 0 auto;
                              gap: 10px;
                              .data-item-display-label {
                                justify-content: center;
                              }
                              .data-item-display-value {
                                text-align: center;
                              }
                            `}
                            label="Transactions"
                            value={
                              <Typography
                                css={css`
                                  font-size: 20px;
                                  font-weight: 700;
                                `}
                              >
                                {transactionsCount}
                              </Typography>
                            }
                          />
                        </Box>
                      </Box>
                    </>
                  ),
                },
                ...(privileges.card_details
                  ? [
                      {
                        label: 'Management',
                        tabOverflow: 'hidden' as const,
                        isAnchor: true,
                        content: (
                          <Box
                            css={css`
                              display: flex;
                              flex-direction: column;
                              gap: 24px;
                              height: 100%;
                              overflow-y: auto;
                              justify-content: start;
                            `}
                          >
                            <Box
                              css={css`
                                padding: 16px 14px 0;
                                display: flex;
                                flex-direction: column;
                                gap: 24px;
                              `}
                            >
                              {reissuedToCard ? (
                                <Box>
                                  <Alert severity="warning">
                                    Card has been reissued and is no longer
                                    editable.
                                  </Alert>
                                </Box>
                              ) : (
                                <>
                                  {!isGroupInactive &&
                                  isCardholderActive &&
                                  isActive &&
                                  !reissuedToCard ? (
                                    <CardSpendControlForm
                                      cardSpendControlAmountCents={
                                        spendLimitCents
                                      }
                                      cardId={stripeId ?? ''}
                                      setUnsavedChanges={
                                        handleSetUnsavedChanges
                                      }
                                      currentSpendCents={spentTotalCents ?? 0}
                                    />
                                  ) : (
                                    <Box>
                                      <Typography
                                        css={css`
                                          flex: 1 1 100%;
                                          padding-bottom: 4px;
                                          font-size: 12px;
                                          font-weight: 500;
                                        `}
                                      >
                                        Card All-Time Spend Limit
                                      </Typography>
                                      <Alert severity="warning">
                                        {[
                                          ...(isGroupInactive ? ['Group'] : []),
                                          ...(isCardholderActive
                                            ? []
                                            : ['Cardholder']),
                                          ...(isActive ? [] : ['Card']),
                                        ].join(' and ')}{' '}
                                        must be active in order to adjust spend
                                        limits.
                                      </Alert>
                                    </Box>
                                  )}
                                  {isGroupInactive ? (
                                    <Box>
                                      <Typography
                                        css={css`
                                          flex: 1 1 100%;
                                          padding-bottom: 4px;
                                          font-size: 12px;
                                          font-weight: 500;
                                        `}
                                      >
                                        Card Nickname
                                      </Typography>
                                      <Alert severity="warning">
                                        Group must be active in order to adjust
                                        spend limits.
                                      </Alert>
                                    </Box>
                                  ) : (
                                    <CardNicknameForm
                                      nickname={nickname ?? ''}
                                      cardId={stripeId ?? ''}
                                      setUnsavedChanges={
                                        handleSetUnsavedChanges
                                      }
                                    />
                                  )}
                                </>
                              )}
                            </Box>
                            <SpendLimitHistory cardId={card?.id ?? ''} />
                            <Box>
                              {privileges?.send_card &&
                                features?.sendCardsViaEmail && (
                                  <>
                                    <PaginatedTableWrapper<
                                      SendCardRecordRaw,
                                      SendCardRecordCleaned
                                    >
                                      endpointUrl={generateAPIPath(
                                        SERVICE_BENJI_CARD_URLS.CARD_GET_SEND_HISTORY,
                                        {
                                          stripeCardId: selectedCardId ?? '',
                                        },
                                      )}
                                      queryKeyBase={queryKeys.cards._sendCardHistoryBaseKey(
                                        { id: selectedCardId ?? '' },
                                      )}
                                      dataFormatter={cleanSendCardRecord}
                                      requiredPrivileges={[Privilege.send_card]}
                                      shouldUseQueryParamsSearchState={false}
                                      pageSize={CARD_SEND_HISTORY_PAGE_SIZE}
                                    >
                                      {({
                                        queryResult: { isLoading, isError },
                                        pagination,
                                        paginationData,
                                        resultCount,
                                        pageCount,
                                      }) => (
                                        <>
                                          {sendCardBlockers.length ? (
                                            <Box
                                              css={css`
                                                padding: 0 14px;
                                              `}
                                            >
                                              <Alert
                                                severity="warning"
                                                css={css`
                                                  flex: 1 1 100%;
                                                `}
                                              >
                                                To send a card:{' '}
                                                {sendCardBlockersErrorMessage}.
                                                {!userData?.accountDetails
                                                  .companySupportEmail &&
                                                  privileges.company_details && (
                                                    <>
                                                      {' '}
                                                      Add a support email here:{' '}
                                                      <Link
                                                        to={generatePath(
                                                          '/secure/profile',
                                                        )}
                                                      >
                                                        Profile
                                                      </Link>
                                                    </>
                                                  )}
                                              </Alert>
                                            </Box>
                                          ) : (
                                            paginationData.pageInfo.total ===
                                              0 &&
                                            isCardholderActive &&
                                            !isGroupInactive &&
                                            isActive && (
                                              <Box
                                                css={css`
                                                  padding: 0 14px;
                                                `}
                                              >
                                                <Button
                                                  variant="contained"
                                                  css={css`
                                                    margin-left: auto;
                                                  `}
                                                  onClick={() =>
                                                    setShowSendCardForm(true)
                                                  }
                                                >
                                                  Send Card
                                                </Button>
                                              </Box>
                                            )
                                          )}
                                          <Box
                                            css={css`
                                              display: flex;
                                              flex-direction: column;
                                              gap: 8px;
                                              padding-bottom: 12px;
                                            `}
                                          >
                                            {(resultCount ?? 0) > 0 && (
                                              <Box
                                                css={css`
                                                  display: flex;
                                                  flex: 1 1 100%;
                                                  align-items: baseline;
                                                  padding: 8px 14px;
                                                  position: sticky;
                                                  z-index: 1;
                                                  top: -1px;
                                                  background-color: ${COLOR_PALETTE.white};
                                                  &:after {
                                                    content: '';
                                                    position: absolute;
                                                    left: 8px;
                                                    right: 8px;
                                                    bottom: 0;
                                                    height: 1px;
                                                    border-bottom: 1px dashed
                                                      ${COLOR_PALETTE.borderMid};
                                                  }
                                                `}
                                              >
                                                <Typography
                                                  css={css`
                                                    font-size: 14px;
                                                    font-weight: 700;
                                                  `}
                                                >
                                                  Card Send History
                                                </Typography>
                                                {!isGroupInactive &&
                                                  isCardholderActive &&
                                                  isActive &&
                                                  !reissuedToCard && (
                                                    <Button
                                                      variant="contained"
                                                      css={css`
                                                        margin-left: auto;
                                                      `}
                                                      onClick={() =>
                                                        setShowSendCardForm(
                                                          true,
                                                        )
                                                      }
                                                    >
                                                      Send Card
                                                    </Button>
                                                  )}
                                              </Box>
                                            )}

                                            {paginationData.nodes.map(
                                              (cardSendHistory, index) => (
                                                <Box
                                                  key={cardSendHistory.id}
                                                  css={css`
                                                    display: flex;
                                                    gap: 8px 16px;
                                                    padding: 14px;
                                                    border-bottom: 1px solid
                                                      ${index + 1 ===
                                                      CARD_SEND_HISTORY_PAGE_SIZE
                                                        ? 'transparent'
                                                        : COLOR_PALETTE.border};
                                                  `}
                                                >
                                                  <Box>
                                                    <Typography
                                                      css={css`
                                                        font-size: 15px;
                                                        font-weight: 600;
                                                      `}
                                                    >
                                                      {index +
                                                        1 +
                                                        ((pagination.state
                                                          .page ?? 1) -
                                                          1) *
                                                          CARD_SEND_HISTORY_PAGE_SIZE}
                                                      .
                                                    </Typography>
                                                  </Box>
                                                  <Box
                                                    css={css`
                                                      display: flex;
                                                      gap: 12px 16px;
                                                      flex-wrap: wrap;
                                                    `}
                                                  >
                                                    <Typography
                                                      css={css`
                                                        flex: 1 1 100%;
                                                        font-weight: 600;
                                                      `}
                                                    >
                                                      {
                                                        cardSendHistory.prettySentDate
                                                      }
                                                    </Typography>
                                                    <Box
                                                      css={css`
                                                        width: 100%;
                                                        display: flex;
                                                        gap: 12px 18px;
                                                      `}
                                                    >
                                                      <DataItemDisplay
                                                        css={css`
                                                          flex: 1 0 208px;
                                                        `}
                                                        size="small"
                                                        label="SENT TO"
                                                        value={
                                                          <>
                                                            {cardSendHistory.type ===
                                                            'member' ? (
                                                              <span className="highlight-block">
                                                                {startCase(
                                                                  cardSendHistory
                                                                    .member.name
                                                                    .first,
                                                                )}{' '}
                                                                {startCase(
                                                                  cardSendHistory
                                                                    .member.name
                                                                    .last,
                                                                )}{' '}
                                                                (
                                                                {startCase(
                                                                  cardSendHistory.type,
                                                                )}
                                                                )
                                                              </span>
                                                            ) : (
                                                              <>
                                                                <span className="highlight-block">
                                                                  {startCase(
                                                                    cardSendHistory.providerName,
                                                                  )}{' '}
                                                                  (
                                                                  {startCase(
                                                                    cardSendHistory.type,
                                                                  )}
                                                                  )
                                                                </span>
                                                                <br />
                                                                <span>
                                                                  for{' '}
                                                                  <span className="highlight-block">
                                                                    {startCase(
                                                                      cardSendHistory
                                                                        .member
                                                                        .name
                                                                        .first,
                                                                    )}{' '}
                                                                    {startCase(
                                                                      cardSendHistory
                                                                        .member
                                                                        .name
                                                                        .last,
                                                                    )}
                                                                  </span>
                                                                  (Member)
                                                                </span>
                                                              </>
                                                            )}
                                                            <br />
                                                            <Box
                                                              component="span"
                                                              className="highlight-block"
                                                              css={css`
                                                                font-weight: 400;
                                                              `}
                                                            >
                                                              {
                                                                cardSendHistory.recepientEmail
                                                              }
                                                            </Box>
                                                          </>
                                                        }
                                                      />
                                                      <DataItemDisplay
                                                        css={css`
                                                          flex: 0 0 80px;
                                                        `}
                                                        size="small"
                                                        label="VIEWED"
                                                        value={
                                                          <Box
                                                            css={css`
                                                              width: 50px;
                                                              text-align: center;
                                                            `}
                                                          >
                                                            {cardSendHistory.visited
                                                              ? 'X'
                                                              : '-'}
                                                          </Box>
                                                        }
                                                      />

                                                      <Box>
                                                        {!isGroupInactive &&
                                                          isCardholderActive &&
                                                          isActive &&
                                                          hasSpendControlSet &&
                                                          userData
                                                            ?.accountDetails
                                                            .companySupportEmail &&
                                                          !reissuedToCard && (
                                                            <Button
                                                              variant="outlined"
                                                              disabled={
                                                                isResendingCard
                                                              }
                                                              onClick={() => {
                                                                track({
                                                                  label:
                                                                    'Submit send card',
                                                                  type: TrackType.action,
                                                                  actionType:
                                                                    'click',
                                                                  isResend:
                                                                    true,
                                                                  sendCardType:
                                                                    cardSendHistory.type,
                                                                  recepientEmail:
                                                                    cardSendHistory.recepientEmail,
                                                                  hasVisited:
                                                                    cardSendHistory.visited,
                                                                });
                                                                const sendCardBase =
                                                                  {
                                                                    cardId:
                                                                      stripeId ??
                                                                      '',
                                                                    cardSendType:
                                                                      cardSendHistory.type as
                                                                        | 'member'
                                                                        | 'provider',
                                                                    email:
                                                                      cardSendHistory.recepientEmail,
                                                                    emailConfirmation:
                                                                      cardSendHistory.recepientEmail,
                                                                    memberName:
                                                                      {
                                                                        first:
                                                                          cardSendHistory
                                                                            .member
                                                                            .name
                                                                            .first,
                                                                        last: cardSendHistory
                                                                          .member
                                                                          .name
                                                                          .last,
                                                                      },
                                                                    memberDob:
                                                                      (
                                                                        cardSendHistory
                                                                          .member
                                                                          .dob ??
                                                                        ''
                                                                      ).split(
                                                                        'T',
                                                                      )[0] ??
                                                                      '',
                                                                  };
                                                                sendCard(
                                                                  cardType ===
                                                                    CardType.singleUse
                                                                    ? {
                                                                        ...sendCardBase,
                                                                        cardType:
                                                                          CardType.singleUse,
                                                                        providerName:
                                                                          cardSendHistory.providerName,
                                                                        dateOfService:
                                                                          (
                                                                            cardSendHistory.serviceDate ??
                                                                            ''
                                                                          ).split(
                                                                            'T',
                                                                          )[0] ??
                                                                          '',
                                                                      }
                                                                    : {
                                                                        ...sendCardBase,
                                                                        cardType:
                                                                          CardType.ongoingUse,
                                                                      },
                                                                  {
                                                                    onSuccess:
                                                                      () => {
                                                                        track({
                                                                          label:
                                                                            'Sent card',
                                                                          type: TrackType.effect,
                                                                          isSuccessful:
                                                                            true,
                                                                          isResend:
                                                                            true,
                                                                          sendCardType:
                                                                            cardSendHistory.type,
                                                                          recepientEmail:
                                                                            cardSendHistory.recepientEmail,
                                                                          hasVisited:
                                                                            cardSendHistory.visited,
                                                                        });
                                                                        incrementMixpanelProfileData(
                                                                          'sendCardCount',
                                                                        );
                                                                      },
                                                                    onError:
                                                                      () => {
                                                                        track({
                                                                          label:
                                                                            'Sent card',
                                                                          type: TrackType.effect,
                                                                          isSuccessful:
                                                                            false,
                                                                          isResend:
                                                                            true,
                                                                          sendCardType:
                                                                            cardSendHistory.type,
                                                                          recepientEmail:
                                                                            cardSendHistory.recepientEmail,
                                                                          hasVisited:
                                                                            cardSendHistory.visited,
                                                                        });
                                                                      },
                                                                  },
                                                                );
                                                              }}
                                                            >
                                                              Resend
                                                            </Button>
                                                          )}
                                                      </Box>
                                                    </Box>
                                                  </Box>
                                                </Box>
                                              ),
                                            )}
                                          </Box>
                                          <Box
                                            css={css`
                                              padding: 0 14px;
                                            `}
                                          >
                                            <TableLoader
                                              isLoading={isLoading}
                                            />
                                            <TableError
                                              isLoading={isLoading}
                                              isError={isError}
                                            />
                                          </Box>
                                          {(pageCount ?? 0) > 1 && (
                                            <TablePagination
                                              resultCount={resultCount}
                                              pageCount={pageCount}
                                              pagination={pagination}
                                              noSidePadding
                                              css={css`
                                                width: initial;
                                                margin: 0 14px;
                                                position: sticky;
                                                bottom: 0;
                                                background-color: ${COLOR_PALETTE.white};
                                              `}
                                            />
                                          )}
                                          {showSendCardForm && !!cardholder && (
                                            <CardSendForm
                                              cardId={stripeId ?? ''}
                                              cardType={cardType}
                                              hasSpendControlSet={
                                                hasSpendControlSet
                                              }
                                              isCardActive={!!isActive}
                                              isCardholderActive={
                                                !!isCardholderActive &&
                                                !isGroupInactive
                                              }
                                              setUnsavedChanges={
                                                handleSetUnsavedChanges
                                              }
                                              cardholderType={
                                                cardholder.type as CardholderType
                                              }
                                              {...(cardholder.type ===
                                                'member' && {
                                                memberName: cardholder.name,
                                                memberEmail: cardholder.email,
                                                memberDob: cardholder.dob,
                                              })}
                                              onSuccess={() => {
                                                setShowSendCardForm(false);
                                              }}
                                              isResend={(resultCount ?? 0) > 0}
                                              closeForm={() => {
                                                if (hasUnsavedChanges) {
                                                  if (
                                                    showUnsavedChangesWarning
                                                  ) {
                                                    setUnsavedChanges({});
                                                    setShowUnsavedChangesWarning(
                                                      false,
                                                    );
                                                    setShowSendCardForm(false);
                                                  } else {
                                                    setShowUnsavedChangesWarning(
                                                      true,
                                                    );
                                                  }
                                                } else {
                                                  setUnsavedChanges({});
                                                  setShowUnsavedChangesWarning(
                                                    false,
                                                  );
                                                  setShowSendCardForm(false);
                                                }
                                              }}
                                              unsavedChanges={unsavedChanges}
                                              showUnsavedChangesWarning={
                                                hasUnsavedChanges &&
                                                showUnsavedChangesWarning
                                              }
                                            />
                                          )}
                                        </>
                                      )}
                                    </PaginatedTableWrapper>
                                  </>
                                )}
                            </Box>
                          </Box>
                        ),
                      },
                    ]
                  : []),
                ...(privileges?.transactions
                  ? [
                      {
                        label: 'Transactions',
                        tabOverflow: 'hidden' as const,
                        content: (
                          <Box
                            css={css`
                              height: 100%;
                              display: flex;
                              flex-direction: column;
                              padding: 10px 0;
                              gap: 4px 16px;
                              flex: 1 1 auto;
                            `}
                          >
                            <PaginatedTableWrapper<
                              TransactionAuthCombinedRaw,
                              TransactionAuthCombinedCleaned
                            >
                              endpointUrl={
                                SERVICE_BENJI_CARD_URLS.TRANSACTIONS_GET_ALL
                              }
                              filters={{ cardId: selectedCardId ?? '' }}
                              queryKeyBase={queryKeys.transactions._baseKey}
                              dataFormatter={cleanTransactionsAuthsCombined}
                              requiredPrivileges={[Privilege.transactions]}
                              shouldUseQueryParamsSearchState={false}
                              pageSize={TRANSACTIONS_PAGE_SIZE}
                            >
                              {({
                                queryResult: { isLoading, isError },
                                pagination,
                                paginationData,
                                resultCount,
                                pageCount,
                              }) => (
                                <>
                                  {!!resultCount && (
                                    <Box
                                      css={css`
                                        display: flex;
                                        gap: 8px 16px;
                                        padding-bottom: 12px;
                                        margin: 0 14px;
                                        position: relative;
                                        border-bottom: 1px solid
                                          ${COLOR_PALETTE.borderDark};
                                        min-height: 53px;
                                      `}
                                    >
                                      <StyledLink
                                        onClick={() => {
                                          navigate('/secure/transactions', {
                                            queryParams: {
                                              filters: JSON.stringify({
                                                cardId: selectedCardId,
                                              }),
                                            },
                                          });
                                        }}
                                        css={css`
                                          margin-left: auto;
                                          font-weight: 500;
                                          line-height: normal;
                                          align-self: center;
                                          padding: 0 2px;
                                        `}
                                      >
                                        View All
                                      </StyledLink>
                                    </Box>
                                  )}

                                  {!!paginationData.nodes.length && (
                                    <Box
                                      css={css`
                                        display: flex;
                                        flex-direction: column;
                                        flex: 1 1 auto;
                                        gap: 8px;
                                        padding-bottom: 12px;
                                        overflow-y: auto;
                                      `}
                                      ref={tableContainerRef}
                                    >
                                      {paginationData.nodes.map(
                                        (transaction, index) => (
                                          <Box
                                            key={transaction.id}
                                            onClick={() =>
                                              openTransactionModal(
                                                transaction.stripeId,
                                                transaction.type,
                                              )
                                            }
                                            css={css`
                                              display: flex;
                                              gap: 8px 16px;
                                              padding: 14px;
                                              border-bottom: 1px solid
                                                ${index + 1 ===
                                                TRANSACTIONS_PAGE_SIZE
                                                  ? 'transparent'
                                                  : COLOR_PALETTE.border};

                                              &:hover {
                                                background-color: ${COLOR_PALETTE.tableRowHover};
                                                cursor: pointer;
                                              }
                                            `}
                                          >
                                            <Box>
                                              <Typography
                                                css={css`
                                                  font-size: 15px;
                                                  font-weight: 600;
                                                `}
                                              >
                                                {index +
                                                  1 +
                                                  ((pagination.state.page ??
                                                    1) -
                                                    1) *
                                                    TRANSACTIONS_PAGE_SIZE}
                                                .
                                              </Typography>
                                            </Box>
                                            <Box
                                              css={css`
                                                display: flex;
                                                gap: 12px 18px;
                                                flex-wrap: wrap;
                                              `}
                                            >
                                              <Typography
                                                css={css`
                                                  flex: 1 1 100%;
                                                  font-weight: 600;
                                                `}
                                              >
                                                {transaction.prettyDisplayDate}
                                              </Typography>

                                              <DataItemDisplay
                                                css={css`
                                                  flex: 0 0 100%;
                                                `}
                                                size="small"
                                                label="Payment Method"
                                                value={
                                                  <span className="highlight-block">
                                                    {transaction.paymentMethod}
                                                  </span>
                                                }
                                              />
                                              <DataItemDisplay
                                                css={css`
                                                  flex: 0 0 160px;
                                                `}
                                                size="small"
                                                label="Merchant"
                                                value={transaction.merchantName}
                                              />
                                              <DataItemDisplay
                                                css={css`
                                                  flex: 0 0 96px;
                                                `}
                                                size="small"
                                                label="Amount"
                                                value={
                                                  <FancyCurrencyDisplay
                                                    amountCents={
                                                      transaction.amountCents ??
                                                      0
                                                    }
                                                  />
                                                }
                                              />
                                              <DataItemDisplay
                                                css={css`
                                                  flex: 0 0 60px;
                                                `}
                                                size="small"
                                                label="Status"
                                                value={
                                                  <StatusChip
                                                    status={transaction.status}
                                                    type="transaction"
                                                  />
                                                }
                                              />
                                            </Box>
                                          </Box>
                                        ),
                                      )}
                                    </Box>
                                  )}
                                  <TableLoader isLoading={isLoading} />
                                  <TableError
                                    isLoading={isLoading}
                                    isError={isError}
                                  />
                                  <TableNoResults
                                    isLoading={isLoading}
                                    resultCount={resultCount}
                                    noResultMessage="No transactions"
                                    css={css`
                                      height: initial;
                                      flex-grow: 1;
                                    `}
                                  />
                                  {(pageCount ?? 0) > 1 && (
                                    <TablePagination
                                      resultCount={resultCount}
                                      pageCount={pageCount}
                                      pagination={pagination}
                                      noSidePadding
                                      scrollRef={tableContainerRef}
                                    />
                                  )}
                                </>
                              )}
                            </PaginatedTableWrapper>
                          </Box>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          </>
        )}
      </DialogContent>
    </Popover>
  );
};

export const CardDetailsModal = ({
  anchorEl,
}: {
  anchorEl: HTMLElement | null;
}) => {
  return (
    <CardTrackingContextProvider>
      <CardDetailsModalBase anchorEl={anchorEl} />
    </CardTrackingContextProvider>
  );
};
