export enum TopupStatusEnum {
  CANCELED = 'canceled',
  FAILED = 'failed',
  PENDING = 'pending',
  REVERSED = 'reversed',
  SUCCEEDED = 'succeeded',
}
export enum PayoutStatusEnum {
  PAID = 'paid',
  PENDING = 'pending',
  IN_TRANSIT = 'in_transit',
  CANCELED = 'canceled',
  FAILED = 'failed',
}

export enum FundTransferTypeEnum {
  INBOUND_TRANSFER = 'InboundTransfer',
  RECEIVED_CREDIT = 'ReceivedCredit',
  RECEIVED_DEBIT = 'ReceivedDebit',
  CREDIT_REVERSAL = 'CreditReversal',
  DEBIT_REVERSAL = 'DebitReversal',
  OUTBOUND_TRANSFER = 'OutboundTransfer',
  OUTBOUND_PAYMENT = 'OutboundPayment',
  TOP_UP = 'TopUp',
  PAYOUT = 'Payout',
}

export interface FundsTransferRaw {
  id: string;
  stripe_id: string;
  stripe_account_id: string;
  financial_account_id: string | null;
  financial_account_stripe_id: string | null;
  created_at: string;
  amount_cents: number;
  description: string | null;
  statement_descriptor: string | null;
  failure_code: string | null;
  status: string;
  payment_method_type: string | null;
  payment_method_financial_account_stripe_id: string | null;
  method_bank_account_name: string | null;
  method_bank_account_last4: string | null;
  network: string | null;
  expected_arrival_date: string | null;
  entity_type: FundTransferTypeEnum;
  metadata: unknown;
  linked_flow_source_flow_type: string | null;
}

export interface FundsTransferCleaned {
  id: FundsTransferRaw['id'];
  stripeId: FundsTransferRaw['stripe_id'];
  createdAt: FundsTransferRaw['created_at'];
  prettyCreatedAtDate: string;
  isCreatedAtDateSameAsPrev: boolean;
  amountCents: FundsTransferRaw['amount_cents'];
  status: FundsTransferRaw['status'];
  typeLabel: FundsTransferRaw['entity_type'];
  entityType: FundTransferTypeEnum;
}

export enum RecievedCreditFailureCode {
  ACCOUNT_CLOSED = 'account_closed',
  ACCOUNT_FROZEN = 'account_frozen',
  INTERNATIONAL_TRANSACTION = 'international_transaction',
  OTHER = 'other',
}

export enum RecievedCreditNetwork {
  ACH = 'ach',
  CARD = 'card',
  STRIPE = 'stripe',
  US_DOMESTIC_WIRE = 'us_domestic_wire',
}

export enum RecievedCreditStatus {
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
}

export interface ReceivedCreditRaw {
  id: string;
  stripe_id: string;
  account_id: string;
  stripe_account_id: string;
  amount_cents: number;
  status: RecievedCreditStatus;
  created_at: string;
  description: string | null;
  failure_code: RecievedCreditFailureCode | null;
  financial_account_stripe_id: string;
  financial_account_id: string;
  initiating_payment_method_billing_address?: {
    city: string;
    line1: string;
    line2: string | null;
    state: string;
    country: string;
    postal_code: string;
  };
  initiating_payment_method_billing_email: string | null;
  initiating_payment_method_billing_name: string | null;
  initiating_payment_method_type: string | null;
  initiating_payment_method_financial_account_stripe_id: string | null;
  initiating_payment_method_card_stripe_id: string | null;
  initiating_payment_method_bank_account_name: string | null;
  initiating_payment_method_bank_account_last4: string | null;
  initiating_payment_method_bank_account_routing_number: string | null;
  network: RecievedCreditNetwork;
  linked_flow_credit_reversal_stripe_id: string | null;
  linked_flow_issuing_authorization_stripe_id: string | null;
  linked_flow_issuing_transaction_stripe_id: string | null;
  linked_flow_source_flow_stripe_id: string | null;
  linked_flow_source_flow_type: string | null;
  linked_flow_source_flow_details: string | null;
}
export interface ReceivedCreditClean {
  id: string;
  stripeId: string;

  amountCents: number;
  status: RecievedCreditStatus;
  createdAt: string;
  prettyCreatedAtDate: string;
  description: string | null;
  failureCode: RecievedCreditFailureCode | null;
  initiatingPaymentMethod: {
    billingAddress?: {
      city: string;
      line1: string;
      line2: string | null;
      state: string;
      country: string;
      postalCode: string;
    };
    billingEmail: string | null;
    billingName: string | null;
    type: string | null;
    bankAccountName: string | null;
    bankAccountLast4: string | null;
  };
  network: RecievedCreditNetwork;
  typeLabel: string;
  entityType: FundTransferTypeEnum.RECEIVED_CREDIT;
}

export enum RecievedDebitFailureCode {
  ACCOUNT_CLOSED = 'account_closed',
  ACCOUNT_FROZEN = 'account_frozen',
  INSUFFICIENT_FUNDS = 'insufficient_funds',
  INTERNATIONAL_TRANSACTION = 'international_transaction',
  OTHER = 'other',
}

export enum RecievedDebitNetwork {
  ACH = 'ach',
  CARD = 'card',
  STRIPE = 'stripe',
  US_DOMESTIC_WIRE = 'us_domestic_wire',
}

export enum RecievedDebitStatus {
  FAILED = 'failed',
  SUCCEEDED = 'succeeded',
}
export interface ReceivedDebitRaw {
  id: string;
  stripe_id: string;
  account_id: string;
  stripe_account_id: string;
  amount_cents: number;
  status: RecievedDebitStatus;
  created_at: string;
  description: string | null;
  failure_code: RecievedDebitFailureCode | null;
  financial_account_stripe_id: string;
  financial_account_id: string;
  initiating_payment_method_billing_address?: {
    city: string;
    line1: string;
    line2: string | null;
    state: string;
    country: string;
    postal_code: string;
  };
  initiating_payment_method_billing_email: string | null;
  initiating_payment_method_billing_name: string | null;
  initiating_payment_method_type: string;
  initiating_payment_method_financial_account_stripe_id: string | null;
  initiating_payment_method_card_stripe_id: string | null;
  initiating_payment_method_bank_account_name: string | null;
  initiating_payment_method_bank_account_last4: string | null;
  initiating_payment_method_bank_account_routing_number: string | null;
  network: RecievedDebitNetwork;
}
export interface ReceivedDebitClean {
  id: string;
  stripeId: string;
  amountCents: number;
  status: RecievedDebitStatus;
  createdAt: string;
  prettyCreatedAtDate: string;
  description: string | null;
  failureCode: RecievedDebitFailureCode | null;
  initiatingPaymentMethod: {
    billingAddress?: {
      city: string;
      line1: string;
      line2: string | null;
      state: string;
      country: string;
      postalCode: string;
    };
    billingEmail: string | null;
    billingName: string | null;
    type: string;
    bankAccountName: string | null;
    bankAccountLast4: string | null;
  };
  network: RecievedDebitNetwork;
  typeLabel: string;
  entityType: FundTransferTypeEnum.RECEIVED_DEBIT;
}
export enum OutboundTransferReturnedDetailsCode {
  ACCOUNT_CLOSED = 'account_closed',
  ACCOUNT_FROZEN = 'account_frozen',
  BANK_ACCOUNT_RESTRICTED = 'bank_account_restricted',
  BANK_OWNERSHIP_CHANGED = 'bank_ownership_changed',
  DECLINED = 'declined',
  INCORRECT_ACCOUNT_HOLDER_NAME = 'incorrect_account_holder_name',
  INVALID_ACCOUNT_NUMBER = 'invalid_account_number',
  INVALID_CURRENCY = 'invalid_currency',
  NO_ACCOUNT = 'no_account',
  OTHER = 'other',
}

export enum OutboundTransferTrackingDetailsType {
  ACH = 'ach',
  US_DOMESTIC_WIRE = 'us_domestic_wire',
}

export enum OutboundTransferStatus {
  CANCELED = 'canceled',
  FAILED = 'failed',
  POSTED = 'posted',
  PROCESSING = 'processing',
  RETURNED = 'returned',
}
export interface OutboundTransferRaw {
  id: string;
  stripe_id: string;
  account_id: string;
  stripe_account_id: string;
  amount_cents: number;
  status: OutboundTransferStatus;
  created_at: string;
  description: string | null;
  financial_account_stripe_id: string;
  financial_account_id: string;
  destination_payment_method_stripe_id: string | null;
  destination_payment_method_billing_address?: {
    city: string;
    line1: string;
    line2: string | null;
    state: string;
    country: string;
    postal_code: string;
  };
  destination_payment_method_billing_email: string | null;
  destination_payment_method_billing_name: string | null;
  destination_payment_method_type: string;
  destination_payment_method_financial_account_stripe_id: string | null;
  destination_payment_method_financial_account_network: string | null;
  destination_payment_method_bank_account_name: string | null;
  destination_payment_method_bank_account_holder_type: string | null;
  destination_payment_method_bank_account_type: string | null;
  destination_payment_method_bank_account_fingerprint: string | null;
  destination_payment_method_bank_account_last4: string | null;
  destination_payment_method_bank_account_mandate: string | null;
  destination_payment_method_bank_account_network: string | null;
  destination_payment_method_bank_account_routing_number: string | null;
  expected_arrival_date: string;
  metadata: unknown;
  returned_details_code: OutboundTransferReturnedDetailsCode | null;
  returned_details_transaction_stripe_id: string | null;
  statement_descriptor: string | null;
  status_transitions_canceled_at: string;
  status_transitions_failed_at: string;
  status_transitions_posted_at: string;
  status_transitions_returned_at: string;
  tracking_details_type: OutboundTransferTrackingDetailsType | null;
  tracking_details_ach_trace_id: string | null;
  tracking_details_wire_chips: string | null;
  tracking_details_wire_imad: string | null;
  tracking_details_wire_omad: string | null;
  transaction_stripe_id: string;
}
export interface OutboundTransferClean {
  id: string;
  stripeId: string;
  amountCents: number;
  status: OutboundTransferStatus;
  createdAt: string;
  prettyCreatedAtDate: string;
  description: string | null;
  destinationPaymentMethod: {
    billingAddress?: {
      city: string;
      line1: string;
      line2: string | null;
      state: string;
      country: string;
      postalCode: string;
    };
    billingEmail: string | null;
    billingName: string | null;
    type: string;
    bankAccountName: string | null;
    bankAccountHolderType: string | null;
    bankAccountType: string | null;
    bankAccountLast4: string | null;
    bankAccountNetwork: string | null;
  };
  expectedArrivalDate: string;
  returnedDetailsCode: OutboundTransferReturnedDetailsCode | null;
  statementDescriptor: string | null;
  statusTransitions: {
    canceledAt: string;
    failedAt: string;
    postedAt: string;
    returnedAt: string;
  };
  trackingDetailsType: OutboundTransferTrackingDetailsType | null;
  typeLabel: string;
  entityType: FundTransferTypeEnum.OUTBOUND_TRANSFER;
}
export interface TopUpRaw {
  id: string;
  stripe_topup_id: string;
  stripe_source_id: string;
  stripe_account_id: string;
  amount: number;
  created: string;
  currency: string;
  description: string | null;
  statement_descriptor: string | null;
  failure_code: string | null;
  failure_message: string | null;
  expected_availability_date: string | null;
  metadata: unknown;
  source: unknown;
  status: TopupStatusEnum;
}
export interface TopUpClean {
  id: string;
  stripeId: string;
  amountCents: number;
  createdAt: string;
  prettyCreatedAtDate: string;
  description: string | null;
  statementDescriptor: string | null;
  failureCode: string | null;
  failureMessage: string | null;
  expectedAvailabilityDate: string | null;
  status: TopupStatusEnum;
  typeLabel: string;
  entityType: FundTransferTypeEnum.TOP_UP;
}

export interface PayoutRaw {
  id: string;
  stripe_account_id: string;
  account_id: string;
  stripe_payout_id: string;
  amount: number;
  arrival_date: string | null;
  created: string;
  currency: string;
  description: string | null;
  statement_descriptor: string | null;
  destination: string;
  metadata: unknown;
  failure_code: string | null;
  failure_message: string | null;
  status: PayoutStatusEnum;
  type: string;
}
export interface PayoutClean {
  id: string;
  stripeId: string;
  amountCents: number;
  arrivalDate: string | null;
  createdAt: string;
  prettyCreatedAtDate: string;
  description: string | null;
  statementDescriptor: string | null;
  failureCode: string | null;
  failureMessage: string | null;
  status: PayoutStatusEnum;
  typeLabel: string;
  entityType: FundTransferTypeEnum.PAYOUT;
}

export type FundsTransferDetailsRaw =
  | ReceivedCreditRaw
  | ReceivedDebitRaw
  | OutboundTransferRaw
  | TopUpRaw
  | PayoutRaw;
