import { css } from '@emotion/react';
import { Box, Link, Typography } from '@mui/material';

import { useAppRoutes } from 'src/hooks';

export const PageFooter = ({ className }: { className?: string }) => {
  const { navigate, shouldShowContactUsFooterLink } = useAppRoutes();

  return (
    <Box
      className={className}
      css={css`
        padding: 16px;
      `}
    >
      <Typography
        variant="body2"
        component="div"
        css={(theme) => css`
          display: flex;
          flex-wrap: wrap;
          gap: 0 18px;
          flex: 0 1 auto;
          text-align: center;
          font-weight: 300;
          justify-content: center;

          &,
          a {
            font-size: 12px;
            line-height: 20px;
          }

          a {
            cursor: pointer;
            color: ${theme.palette.text.primary};
            text-decoration: underline;
            font-weight: 400;
            &:hover {
              color: ${theme.palette.text.primary};
            }
          }
        `}
      >
        {process.env.REACT_APP_FEATURE_TREASURY === 'true' ? (
          <Box
            css={css`
              line-height: 13px;
              padding-bottom: 2px;
            `}
          >
            Benji Card is neither a bank nor a money transmitter. Benji Card
            partners with{' '}
            <Link
              href="https://stripe.com/"
              target="_blank"
              rel="noreferrer"
              css={css`
                font-weight: 300 !important;
                text-decoration: none !important;
                &:hover {
                  text-decoration: underline !important;
                }
              `}
            >
              Stripe Payments Company
            </Link>{' '}
            for money transmission services and account services with funds held
            at Fifth Third Bank N.A., Member FDIC. Benji Card Visa® Commercial
            Credit cards are powered by Stripe and issued by Celtic Bank
          </Box>
        ) : (
          `
        Benji Card Visa® Commercial Credit cards are powered by Stripe and
        issued by Celtic Bank.
          `
        )}
        <Box
          css={css`
            flex: 0 0 auto;
            display: flex;
            gap: 0 18px;
          `}
        >
          <Link
            variant="body2"
            onClick={() => navigate('/legal')}
            color="inherit"
            css={css`
              white-space: nowrap;
            `}
          >
            Legal
          </Link>
          {shouldShowContactUsFooterLink && (
            <Link
              variant="body2"
              onClick={() => navigate('/contact-us')}
              color="inherit"
              css={css`
                white-space: nowrap;
              `}
            >
              Contact Us
            </Link>
          )}
        </Box>
      </Typography>
    </Box>
  );
};
